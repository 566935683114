// extracted by mini-css-extract-plugin
export var caseContactFormSection = "b4_kz";
export var caseStudyBackground__lineColor = "b4_kq";
export var caseStudyHead__imageWrapper = "b4_ns";
export var caseStudyProjectsSection = "b4_kB";
export var caseStudyQuote__bgRing = "b4_kw";
export var caseStudyQuote__bottomVideo = "b4_kL";
export var caseStudyQuote__light = "b4_mg";
export var caseStudyQuote__lightLast = "b4_nt";
export var caseStudyVideo__ring = "b4_kF";
export var caseStudy__bgDark = "b4_km";
export var caseStudy__bgLight = "b4_kl";
export var caseStudy__bgLightReverse = "b4_ly";